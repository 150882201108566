import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';



const Clue9 = () => (
  <Layout>
    <Header /> 

    <header className="masthead9">
    <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #9</h1>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
          <br></br>
          <br></br>
            {/* {config.subHeading} */}
            East of 3rd is
            <br></br>
            where you will go.
            <br></br>
            <br></br>
            But not for hangs
            <br></br>
            out on our patio.
            <br></br>
            <br></br>
            It's color the same
            <br></br>
            as a clear blue sky.
            <br></br>
            <br></br>
            Dance on over
            <br></br>
            no need to be shy.
            <br></br>
            <br></br>
            A place you can go 
            <br></br>
            and kick up your feet.
            <br></br>
            <br></br>
            Choose carefully or
            <br></br>
            you will get the soggy seat.
            <br></br>
          </h2>
          <br></br>
             <a href="/hint9" className="btn btn-primary">
              Hint
            </a>  
            <br></br>
            <br></br> 
            <br></br>
            <br></br> 
            <br></br>
            <br></br>         
        </div>
      </div>
    </header>

    {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue9;
